<template>
  <div class="my-calendar-item">
    <span>
      {{ text }}
      <div class="item-status">
        <div v-if="haveColor('green')" class="green status"></div>
        <div v-if="haveColor('blue')" class="blue status"></div>
        <div v-if="haveColor('red')" class="red status"></div>
        <div v-if="haveColor('yellow')" class="yellow status"></div>
        <div v-if="haveColor('white')" class="white status"></div>
        <div v-if="haveColor('purple')" class="purple status"></div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: "calendarItem",
  props: ["text", "colorList"],
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/27 18:01:02
     * @Description 判断当前日期的巡检任务有没有某种类型，有就显示颜色
     */
    haveColor(color) {
      let type = typeof this.text;
      if (type.indexOf("string") > -1) {
        return false;
      }

      let flag = this.colorList.findIndex(s => {
        if (s.toUpperCase().indexOf(color.toUpperCase()) > -1) {
          return s;
        }
      });
      return flag > -1;
    }
  }
};
</script>
<style lang="scss" scoped>
.my-calendar-item {
  .item-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 2.5vw;
  }
  .status {
    width: 0.5vh;
    height: 0.5vh;
    border-radius: 50%;
    margin: 0.1vh;
  }
  .green {
    background-color: green;
  }
  .red {
    background-color: red;
  }
  .blue {
    background-color: blue;
  }
  .yellow {
    background-color: yellow;
  }
  .white {
    background-color: white;
  }
  .purple {
    background-color: purple;
  }
}
</style>
