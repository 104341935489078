<template>
  <div class="my-calendar">
    <div>
      <table border="1">
        <tr>
          <th v-for="(week, index) in week" :key="index">{{ week }}</th>
        </tr>
        <tr v-for="(dayRow, i) in days" :key="i">
          <td v-for="(day, j) in dayRow" :key="j" :class="{ 'plan-color': isHavePlan(day), hasHover: isHaveHover(i, j, day), choosed: i === currI && j === currJ }" @click="clickDay(i, j, day)">
            <!-- 如果是第一行，理论上来说，number最大为7，所以大于7的日期为上个月的日期，不显示 -->
            <calendar-item v-if="i == 0" :text="i == 0 && day > 7 ? '' : day" :colorList="getColors(day)" />
            <!-- 如果是倒数两行，理论上来说，获取了6 * 7的日历，最极端情况是最后两行是1-14（下个月的时间），所以小于14的日期为下个月的日期，不显示 -->
            <calendar-item v-else-if="i >= 4" :text="14 > day ? '' : day" :colorList="getColors(day)" />
            <!-- 其他行正常显示 -->
            <calendar-item v-else :text="day" :colorList="getColors(day)" />
          </td>
        </tr>
      </table>
    </div>

    <div style="width: 8vw; position: absolute; right: 1vh">
      任务状态标识
       <div  style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: white"/><div>未开始</div></div>
        <div style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: blue"/><div>正在执行</div></div>
        <div style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: green"/><div>已完成</div></div>
        <div style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: red"/><div>失败</div></div>
        <div style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: yellow"/><div>废弃</div></div>
        <div style="display: flex; flex-direction: row; margin: 5px"><div style="width: 1.2vh;height: 1.2vh;border-radius: 50%; margin: 0.5vh; background: purple"/><div>停止</div></div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import calendarItem from "./calendarItem.vue";
export default {
  name: "calendar",
  props: {
    nowTime: Number,
    hasPlan: Array
  },
  components: {
    calendarItem
  },
  data() {
    return {
      color: ["green", "yellow", "red", "blue", "white", "purple"],
      week: ["日", "一", "二", "三", "四", "五", "六"],
      days: [],
      total: 0,
      // hasPlan: [], //当月有任务的日期
      currI: "",
      currJ: "",
      date: "",
      choosedDay: "" //点击的日期
    };
  },
  computed: {},
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/27 17:56:35
     * @Description 当天是否含有巡检计划,字体颜色填充了绿色
     */
    isHavePlan(day) {
      let index = this.hasPlan.findIndex(p => {
        if (p.date === day) {
          return p;
        }
      });
      return index !== -1;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/27 17:56:44
     * @Description 获取日期下面颜色列表
     */
    getColors(day) {
      let index = this.hasPlan.findIndex(p => {
        if (p.date === day) {
          return p;
        }
      });

      return index > -1 ? this.hasPlan[index].colors : [];
    },

    /**
     * @Author KR0288
     * @Date 2022/06/27 17:58:43
     * @Description 只有显示了number的日期可以有hover效果
     */
    isHaveHover(i, j, day) {
      if (i == 0 && day > 7) {
        return false;
      } else if (i >= 4 && day < 14) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/27 17:28:46
     * @Description 获取月份数组
     */
    monthDay(date) {
      // 1、6*7的日历数组
      const daysArr = [[], [], [], [], [], []]; 

      // 2、获取当月1日为星期几, 3
      const currentWeekday = moment(date).date(1).weekday();   
      
      // 3、获取上月天数, 31
      const lastMonthDays = moment(date).subtract(1, "month").daysInMonth(); 

      // 4、获取当月天数, 30
      const currentMonthDays = moment(date).daysInMonth(); 
      this.total = currentMonthDays;

      // 5、日期处理,生成完整的6*7日历
      const getDay = day => (day <= lastMonthDays ? day : day <= lastMonthDays + currentMonthDays ? day - lastMonthDays : day - (lastMonthDays + currentMonthDays)); 

      for (let i = 0; i < 7; i += 1) {
        let virtualDay = lastMonthDays - currentWeekday + i + 1;
        for (let j = 0; j < 6; j += 1) {
          daysArr[j][i] = getDay(virtualDay + j * 7);
        }
      }
      return daysArr;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/27 17:39:00
     * @Description 点击日期
     */
    clickDay(i, j, day) {
      if (this.isHaveHover(i, j, day)) {
        this.currI = i;
        this.currJ = j;
        this.choosedDay = this.days[i][j];
        if (this.choosedDay < 10) {
          this.choosedDay = "0" + this.choosedDay;
        }
        this.$emit("getDate", this.choosedDay);
      }
    }
  },
  watch: {
    nowTime: function(newV) {
      this.days = this.monthDay(newV);
      // if (this.choosedDay) {
      //   this.date = Number(this.choosedDay) < 10 ? Number(this.choosedDay) : this.choosedDay;
      // } else {
      //   this.date = moment().get("date");
      // }

      this.date = moment(newV).get("date");
      let ij = [];
      if (this.date > 7) {
        for (let i = 1; i < this.days.length; i++) {
          const jArr = this.days[i];
          for (let j = 0; j < jArr.length; j++) {
            const date = jArr[j];
            if (date == this.date) {
              ij = [i, j];
            }
          }
        }
      } else {
        // 日期小于7
        for (let n = 0; n < this.days[0].length; n++) {
          const element = this.days[0][n];
          if (element == this.date) {
            ij = [0, n];
          }
        }
      }
      this.currI = ij[0];
      this.currJ = ij[1];
      console.log(this.currI, this.currJ);
    }
  },
};
</script>
<style lang="scss" scoped>
.my-calendar {
  height: 100%;
  display: flex;
  justify-content: center;
  table {
    border: none;
    height: 100%;

    tr {
      border: none;
      th {
        border: none;
        padding: 1vh 1vw;
      }
      td {
        border: none;
        padding: 0.5vh 1vw;
        font-size: 2vh;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
