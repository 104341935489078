<template>
  <div class="inspection-page">
    <RouterMenu />
    <div class="main">
      <div class="top-calendar">
        <div class="month">
          <el-date-picker v-model="month" type="month" placeholder="选择月" format="yyyy 年 MM 月" value-format="timestamp" :clearable="false"></el-date-picker>
          <div class="add-button">
            <el-button class="kr-normal-button" @click="showFilterInspectPlanDialog" v-if="$hasElement('plan-form-get')">筛选任务</el-button>
            <el-button class="kr-normal-button" @click="showInspectionPlanDialog" v-if="$hasElement('plan-form-add')">添加任务</el-button>
            <el-button class="kr-normal-button" @click="showTemplateTable('init')" v-if="$hasElement('planTemplate-form-get')">任务模板</el-button>
          </div>
        </div>
        <div class="up-line"></div>
        <div class="calendar">
          <keep-alive :exclude="['calendar']">
            <calendar v-bind:now-time="nowTime" v-bind:has-plan="hasPlan" ref="calendar" @getDate="getDay" /> 
          </keep-alive>   
        </div>
        <div class="down-line"></div>
      </div>

       <div style=" margin-bottom: 2px">
          <el-button size="mini" class="kr-normal-button" @click="showTask">任务信息</el-button>
          <el-button size="mini" class="kr-normal-button" @click="showState">机器状态</el-button>
        </div>
      <!-- 巡检计划表格 -->
      <div class="plan-table" v-show="type == 'plan'">
        <el-table :data="planTableData" border style="width: 100%" height="46vh" empty-text="没有符合查询条件的巡检任务">
          <el-table-column type="index" label="序号" width="80" align="center">
            <template slot-scope="scope">
              <Icon name="bottomArrow" />
              <span>{{ tableIndex(scope) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="planName" label="任务名称" align="center" width="150"></el-table-column>
          <el-table-column prop="lineName" label="线路名" align="center"></el-table-column>
          <el-table-column prop="robotName" label="执行机器" align="center"></el-table-column>
          <el-table-column prop="executeTime" label="执行时间" align="center" width="200"></el-table-column>
          <el-table-column prop="inspectMode" label="任务类型" align="center" width="150">
            <template slot-scope="scope">
              <span>{{ getMapValue(planModeList, scope.row.inspectMode) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="beginTower.towerName" label="起始塔" align="center"></el-table-column>
          <el-table-column prop="endTower.towerName" label="结束塔" align="center"></el-table-column>
          <el-table-column prop="returnTower.towerName" label="返回塔" align="center"></el-table-column>
          <el-table-column prop="dir" label="方向" align="center">
            <template slot-scope="scope">
              {{ scope.row.dir ? scope.row.dir == 1 ? "前进" : "后退" : "未知" }}
            </template>
          </el-table-column>
          <el-table-column prop="ready" label="下发状态" align="center">
            <template slot-scope="scope">
              <span>{{ getMapValue(planReadyStatusList, scope.row.ready) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="planStatus" label="执行状态" align="center">
            <template slot-scope="scope">
              <span>{{ getMapValue(planStatusList, scope.row.planStatus) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="exception" label="异常信息" align="center"></el-table-column>
           <el-table-column prop="finishTime" label="完成时间" align="center" width="200"></el-table-column>

          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text" class="table-btn" v-if="$hasElement('plan-form-update')"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
              <el-button @click="handleDelete(scope.row)" type="text" class="table-btn" v-if="$hasElement('plan-form-delete')"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager-class">
          <!-- <el-pagination @current-change="handleFilterPlan(true)" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination> -->
          <el-pagination @current-change="handleChangePage" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
      <!-- 机器状态-->
        <div class="robot-table" v-show="type == 'state'">
          <el-table :data="robotStateTableData" border style="width: 100%" height="46vh">
            <el-table-column prop="robotName" label="机器名" align="center"></el-table-column>
            <el-table-column prop="velocity" label="机器电量" align="center"></el-table-column>
            <el-table-column prop="currentTowernumber" label="当前杆塔" align="center"></el-table-column>
            <el-table-column prop="currentDistance" label="当前位置" align="center"></el-table-column>
            <el-table-column prop="nextTowernumber" label="下一杆塔" align="center"></el-table-column>
            <el-table-column prop="nextDistance" label="下一位置" align="center"></el-table-column>
          </el-table>
     
        </div>
         <!-- 机器状态结束-->

    </div>

    <!-- 添加巡检任务弹出框 -->
    <el-dialog
      class="my-dialog add-plan-dialog"
      :title="isAdd ? '新增任务' : '编辑任务'"
      :close-on-click-modal="false"
      :visible.sync="inspectionPlanFormVisible"
      @closed="closedDialog('inspectionPlanFormRef')"
    >
      <el-form :model="inspectionPlanForm" ref="inspectionPlanFormRef" :rules="inspectionPlanFormRules">
        <el-form-item label="任务名称" :label-width="formLabelWidth" prop="planName">
          <el-input v-model="inspectionPlanForm.planName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="线路名称" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model="inspectionPlanForm.lineId" placeholder="请选择执行线路" @change="getTowerByLineId(true, true, inspectionPlanForm.lineId)">
            <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行机器人" :label-width="formLabelWidth" prop="robotId">
          <el-select v-model="inspectionPlanForm.robotId" placeholder="请选择执行任务机器人">
            <el-option v-for="(item, index) in robotList" :key="index" :label="item.robotName" :value="item.robotId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检模板" :label-width="formLabelWidth" prop="planTemplate">
          <el-select v-model="planTemplate" placeholder="请选择巡检模板" @change="selectTemplates">
            <el-option v-for="(item, index) in taskTemplateList" :key="index" :label="item.templateName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行时间" :label-width="formLabelWidth" prop="executeTime">
          <el-date-picker v-model="inspectionPlanForm.executeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="任务类型" :label-width="formLabelWidth" prop="inspectMode" class="">
          <el-radio-group v-model="inspectionPlanForm.inspectMode" class="inspect-type-radio">
            <el-radio v-for="item in planModeList" :key="item.key" :label="item.key">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="taskTower" label="起始塔杆" :label-width="formLabelWidth" prop="beginTowerIndex">
          <el-select v-model="inspectionPlanForm.beginTowerIndex" placeholder="请先选起始杆塔">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>
          <el-checkbox v-model="inspectionPlanForm.throughBeginTower">穿过此塔</el-checkbox>
        </el-form-item>
        <el-form-item class="taskTower" label="终止塔杆" :label-width="formLabelWidth" prop="endTowerIndex">
          <el-select v-model="inspectionPlanForm.endTowerIndex" placeholder="请先选终止杆塔">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>

          <el-checkbox v-model="inspectionPlanForm.throughEndTower">穿过此塔</el-checkbox>
        </el-form-item>
        <el-form-item class="taskTower" label="返回塔杆" :label-width="formLabelWidth" prop="returnTowerIndex">
          <el-select v-model="inspectionPlanForm.returnTowerIndex" placeholder="请先选择返回杆塔">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>

          <el-checkbox v-model="inspectionPlanForm.throughReturnTower">穿过此塔</el-checkbox>
        </el-form-item>
        <el-form-item label="方向" :label-width="formLabelWidth" prop="dir">
          <el-select v-model="inspectionPlanForm.dir" placeholder="请选择方向">
            <el-option label="前进" :value="1"></el-option>
            <el-option label="后退" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="inspectionPlanFormVisible = false">取 消</el-button>
        <el-button class="save-button" @click="save('inspectionPlanFormRef')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 任务模板表格弹出框 -->
    <el-dialog class="my-dialog task-template-dialog" :close-on-click-modal="false" :visible.sync="taskTemplateTableVisible">
      <span slot="title" class="dialog-footer">
        <el-button class="kr-normal-button" @click="addTemplateBtn" v-if="$hasElement('planTemplate-form-add')">添加模板</el-button>
      </span>
      <el-table :data="templateTableData" border height="48vh">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <Icon name="bottomArrow" />
            <span>{{ templateTableIndex(scope.$index) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="templateName" label="模板名称" align="center" width="140"></el-table-column>
        <el-table-column prop="inspectMode" label="任务类型" align="center" width="140">
          <template slot-scope="scope">
            <span>{{ getMapValue(planModeList, scope.row.inspectMode) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lineName" label="线路" align="center"></el-table-column>
        <el-table-column prop="towerCount" label="塔数" align="center"></el-table-column>
        <el-table-column prop="beginTower.towerName" label="起始塔" align="center" width="120"></el-table-column>
        <el-table-column label="穿过起始塔" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.beginTower==null?"":scope.row.beginTower.isThroughout ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="endTower.towerName" label="终止塔" align="center" width="120"></el-table-column>
        <el-table-column label="穿过结束塔" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.endTower==null?"":scope.row.endTower.isThroughout ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="returnTower.towerName" label="返回塔" align="center" width="120"></el-table-column>
        <el-table-column label="穿过返回塔" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.returnTower==null?"":scope.row.returnTower.isThroughout ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleEditTemplate(scope.row)" type="text" class="table-btn" v-if="$hasElement('planTemplate-form-update')"><i class="el-icon-edit" style="color:#fff;font-size:1.5vh;"></i></el-button>
            <el-button @click="handleDeleteTemplate(scope.row)" type="text" class="table-btn" v-if="$hasElement('planTemplate-form-delete')"><i class="el-icon-delete" style="color:#fff;"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
          <div class="pager-class">
          <!-- <el-pagination background layout="prev, pager, next" :total="total"></el-pagination> -->
          <el-pagination @current-change="templatePageChange(pageNumber)" :current-page.sync="pageNumber" :page-size="pageSize" layout="total, prev, pager, next" :total="tempTotal"></el-pagination>
        </div>
    </el-dialog>

    <!-- 添加模板弹出框 -->
    <el-dialog
      class="my-dialog add-plan-dialog"
      :title="isAddTemplate ? '新增巡检模板' : '编辑巡检模板'"
      :close-on-click-modal="false"
      :visible.sync="inspectionTemplateFormVisible"
      @closed="closedDialog('inspectionTemplateForm')"
    >
      <el-form :model="inspectionTemplateForm" ref="inspectionTemplateForm" :rules="inspectionTemplateFormRules">
        <el-form-item label="模板名称" :label-width="formLabelWidth" prop="planName">
          <el-input v-model="inspectionTemplateForm.planName" auto-complete="off" placeholder="请输入巡检任务模板名称"></el-input>
        </el-form-item>
        <el-form-item label="线路名称" :label-width="formLabelWidth" prop="lineId">
          <el-select v-model="inspectionTemplateForm.lineId" placeholder="请选择巡检线路" @change="getTowerByLineId(true, false, inspectionTemplateForm.lineId)">
            <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="任务类型" :label-width="formLabelWidth" prop="inspectMode">
          <el-radio-group v-model="inspectionTemplateForm.inspectMode" class="inspect-type-radio">
            <el-radio v-for="item in planModeList" :key="item.key" :label="item.key">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="taskTower" label="起始塔杆" :label-width="formLabelWidth" prop="beginTowerIndex">
          <el-select v-model="inspectionTemplateForm.beginTowerIndex" placeholder="请先选起始杆塔">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>
          <el-checkbox v-model="inspectionTemplateForm.throughBeginTower">穿过此塔</el-checkbox>
        </el-form-item>
        <el-form-item class="taskTower" label="终止塔杆" :label-width="formLabelWidth" prop="endTowerIndex">
          <el-select v-model="inspectionTemplateForm.endTowerIndex" placeholder="请先选终止杆塔">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>

          <el-checkbox v-model="inspectionTemplateForm.throughEndTower">穿过此塔</el-checkbox>
        </el-form-item>
        <el-form-item class="taskTower" label="返回塔杆" :label-width="formLabelWidth" prop="returnTowerIndex">
          <el-select v-model="inspectionTemplateForm.returnTowerIndex" placeholder="请先选返回塔杆">
            <el-option v-for="(item, index) in currTowerList" :key="index" :label="item.towerName" :value="item.towerIndex"></el-option>
          </el-select>
          <el-checkbox v-model="inspectionTemplateForm.throughReturnTower">穿过此塔</el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="cancelAddTemplate">取 消</el-button>
        <el-button class="save-button" @click="saveTemplate('inspectionTemplateForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 筛选巡检任务弹出框 -->
    <el-dialog class="my-dialog add-plan-dialog" title="筛选任务" :close-on-click-modal="false" :visible.sync="filterInspectPlanDialogVisible" @close="handleCloseFilterDialog">
      <el-form :model="filterInspectForm" ref="filterInspectFormRef" :rules="filterInspectFormRules"  prop="type">
        <el-form-item label="任务名称" :label-width="formLabelWidth" prop="planName">
          <el-input v-model="filterInspectForm.planName" auto-complete="off" placeholder="请输入巡检任务名关键字"></el-input>
        </el-form-item>
        <el-form-item label="巡检线路" :label-width="formLabelWidth" prop="lineName">
          <el-select v-model="filterInspectForm.lineId" placeholder="请选择巡检线路">
            <el-option v-for="(item, index) in lineList" :key="index" :label="item.lineName" :value="item.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型" :label-width="formLabelWidth" prop="inspectMode">
          <el-select v-model="filterInspectForm.inspectMode" placeholder="请选择巡检任务类型">
            <el-option v-for="item in planModeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" :label-width="formLabelWidth" prop="planStatus">
          <el-select v-model="filterInspectForm.planStatus" placeholder="请选择巡检任务状态">
            <el-option v-for="item in planStatusList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起始日期" :label-width="formLabelWidth" prop="beginDate">
          <el-date-picker v-model="filterInspectForm.beginDate" type="date" value-format="yyyy-MM-dd" placeholder="选择起始日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" :label-width="formLabelWidth" prop="endDate">
          <el-date-picker v-model="filterInspectForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="选择结束日期时间"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="handleFilterPlan(false)">取消筛选</el-button>
        <el-button class="save-button" @click="handleFilterPlan(true)">应用筛选</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCheckPlans,
  postAddPlan,
  postUpdatePlan,
  getDeletePlan,
  postAddPlanTemplate,
  getDeletePlanTemplate,
  postUpdatePlanTemplate,
  getPlanTemplate,
  getInspectMode,
  getPlanStatus,
  getPlanReadyStatus,
  getLineRobots,
  getContainPlanDateInMonth,
  fuzzyQueryPlans
} from "@/http/inspectionPlanApi.js";
import { getTowerNameList } from "@/http/towerListDetailsApi.js";
import { getLineNames } from "@/http/homeApi.js";
import moment from "moment";
import RouterMenu from "@/components/RouterMenu.vue";
import calendar from "@/components/calendar";
import Icon from "@/components/Icon";
import { mapState } from "vuex";
export default {
  name: "inspect",
  components: {
    RouterMenu,
    calendar,
    Icon
  },
  data() {
    return {
      /*
       * @Date 2022/06/06 10:45:12
       * 日历信息
       */
      month: "",                // 当前月份
      nowTime: 0,               // 切换月份后的当前时间
      isClickCalendar: true,    // 是否点击日历
      calendarDateString: "",   // 当前点击的日期str
      hasPlan: [],              // 包含巡检任务的日期
      planStatusColors: [       // 日历上有巡检任务的文字颜色
        { color: "white", status: "PLAN_STATUS_READY" },
        { color: "green", status: "PLAN_STATUS_COMPLETE" },
        { color: "blue", status: "PLAN_STATUS_RUNNING" },
        { color: "yellow", status: "PLAN_STATUS_DISCARD" },
        { color: "red", status: "PLAN_STATUS_FAILED" },
        { color: "purple", status: "PLAN_STATUS_STOP" }
      ],

      /*
       * @Date 2022/06/06 10:47:32
       * 巡检任务表格和机器状态信息
       */
      type: "plan",
      planTableData: [], 
      robotStateTableData: [],
      planReadyStatusList: [], // 任务状态类型列表
      total: 0,
      pageNumber: 1,
      pageSize: 10,


      /*
       * @Date 2022/06/06 10:46:12
       * 筛选任务信息
       */
      filterInspectPlanDialogVisible: false, // 显示筛选巡检任务对话框
      formLabelWidth: "5vw",                 // label宽度
      planModeList: [],                      // 任务模式列表
      planStatusList: [],                    // 任务状态类型列表
      lineList: [],
      isFilter: false,                       // 是否是查询
      filterInspectForm: {                   // 过滤查询巡检任务表单
        lineId: "",
        planName: "",
        inspectMode: "",
        planStatus: "",
        beginDate: "",
        endDate: "",
        pageNumber: "",
        pageSize: ""
      },
      filterInspectFormRules: {              // 过滤查询巡检任务表单验证
        beginDate: [{ required: true, message: "请选择起始时间", trigger: "blur" }],
        endDate: [{ required: true, message: "请选择起始时间", trigger: "blur" }]
      },


      /*
       * @Date 2022/06/06 10:56:33
       * 添加任务信息
       */
      isAdd: true,                      
      inspectionPlanFormVisible: false, 
      currTowerList: [],                // 线路塔杆集合
      taskTemplateList: [],             // 任务模板列表
      planTemplate: "",                 // 当前选择的模板
      robotList: [],                    // 线路对应的机器列表
      inspectionPlanForm: {             // 新增 / 编辑巡检任务表单
        lineId: "",
        robotId: "",
        planName: "",
        beginTowerIndex: "",
        endTowerIndex: "",
        returnTowerIndex: "",
        throughBeginTower: false,
        throughEndTower: false,
        throughReturnTower: false,
        executeTime: "",
        inspectMode: "",
        dir: -1
      },
      inspectionPlanFormRules: {       // 新增 / 编辑巡检任务表单验证
        lineId: [{ required: true, message: "请选择巡检任务对应的线路", trigger: "blur" }],
        robotId: [{ required: true, message: "请选择执行任务的机器人", trigger: "blur" }],
        inspectMode: [{ required: true, message: "请选择任务类型", trigger: "blur" }],
        planName: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        executeTime: [{ required: true, message: "请选择执行时间", trigger: "blur" }],
        beginTowerIndex: [{ required: true, message: "请选择起始塔", trigger: "blur" }],
        endTowerIndex: [{ required: true, message: "请选择终止塔", trigger: "blur" }],
        dir: [{ required: true, message: "请选择方向", trigger: "blur" }]
      },


      /*
       * @Date 2022/06/06 11:01:46
       * 任务模板信息
       */
      taskTemplateTableVisible: false,        // 任务模板表格对话框
      inspectionTemplateFormVisible: false,   // 添加模板对话框
      isAddTemplate: true,
      templateTableData: [],                  // 巡检任务模板表格
      tempTotal: 0,                           // 任务模板总数
      taskList: [                             // 任务类型
        { label: "精细化巡检", modeValue: "2" },
        { label: "通道巡检", modeValue: "1" },
        { label: "无拍摄巡检", modeValue: "0" },
        { label: "红外巡检", modeValue: "3" }
      ],
      inspectionTemplateForm: {               // 模板表单
        lineId: "",
        planName: "",
        inspectMode: "",
        beginTowerIndex: "",
        endTowerIndex: "",
        returnTowerIndex: "",
        throughBeginTower: false,
        throughEndTower: false,
        throughReturnTower: false
      }, 
      inspectionTemplateFormRules: {         // 模板表单验证
        lineId: [{ required: true, message: "请选择巡检线路", trigger: "blur" }],
        inspectMode: [{ required: true, message: "请选择任务类型", trigger: "blur" }],
        planName: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        beginTowerIndex: [{ required: true, message: "请选择起始塔", trigger: "blur" }],
        endTowerIndex: [{ required: true, message: "请选择终止塔", trigger: "blur" }],
        // returnTowerIndex: [{ required: true, message: "请选择返回塔", trigger: "blur" }]
      },
      isDisabledDirection: false
    };
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/06 10:35:24
     * @Description 获取点击的日历时间
     */
    getDay(data) {
      var dateString = moment(this.month).format("yyyy-MM") + "-" + data;
      this.calendarDateString = dateString;
      this.isClickCalendar = true;
      this.isFilter = false;
      this.getInspectionPlanList(dateString);
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:14:27
     * @Description 获取巡检计划列表表格
     */
    getInspectionPlanList(date) {
      var dateString = date || moment().format("yyyy-MM-DD");
      this.pageNumber = 1;
      let params = { 
        orderDate: dateString, 
        pageNumber: this.pageNumber, 
        pageSize: this.pageSize 
      };
      getCheckPlans(params).then(res => {
        this.total = res.total;
        this.planTableData = res.rows;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:42:37
     * @Description 自定义表格顺序
     */
    tableIndex(scope) {
      for (let item in scope) {
        if (item?.indexOf("$index") > -1) {
          return scope[item] + 1;
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:44:18
     * @Description 根据任务类型key值获取任务value
     */
    getMapValue(list, key) {
      let item = list.find(p => {
        if (p?.key?.indexOf(key) > -1) {
          return p;
        }
      });
      return item ? item.value : "";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:19:53
     * @Description 查询线路名称
     */
    getLineList() {
      getLineNames().then(res => {
        this.lineList = res.data;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/14 15:06:29
     * @Description 查询巡检任务相关的字典值
     */
    getPlanDictList() {
      /*
       * @Date 2022/02/14 15:06:34
       * 查询任务状态字典值
       */
      getPlanStatus().then(res => {
        if (res.code === "000000") {
          this.planStatusList = res.data;
        }
      });
      /*
       * @Date 2022/02/14 15:06:44
       * 查询任务类型字典值
       */
      getInspectMode().then(res => {
        if (res.code === "000000") {
          this.planModeList = res.data;
        }
      });
      /*
       * @Date 2022/02/14 15:07:03
       * 查询下发状态字典值
       */
      getPlanReadyStatus().then(res => {
        if (res.code === "000000") {
          this.planReadyStatusList = res.data;
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/15 10:41:15
     * @Description 显示筛选任务对话框
     */
    showFilterInspectPlanDialog() {
      if (this.filterInspectForm.beginDate.length == 0) {
        this.filterInspectForm.beginDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
      }
      if (this.filterInspectForm.endDate.length == 0) {
        this.filterInspectForm.endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      this.filterInspectPlanDialogVisible = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/15 10:13:12
     * @Description 应用模糊查询
     */
    handleFilterPlan(filter) {
      this.isFilter = filter;
      if (filter) {
        let params = JSON.parse(JSON.stringify(this.filterInspectForm));
        params.pageNumber = this.pageNumber;
        params.pageSize = this.pageSize;
        for (let item in params) {
          if (params[item].length == 0) {
            delete params[item];
          }
        }
        fuzzyQueryPlans(params).then(res => {
          this.total = res.total;
          this.planTableData = res.rows;
          this.isClickCalendar = false;
        });
      } else {
        this.getInspectionPlanList(this.calendarDateString);
      }
      this.filterInspectPlanDialogVisible = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/08/10 09:41:29
     * @Description 关闭筛选任务对话框
     */
    handleCloseFilterDialog() {
      //清空表单数据
      // this.$refs.filterInspectFormRef.resetFields();
      this.filterInspectForm.lineId = "";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:19:05
     * @Description 显示添加任务对话框
     */
    showInspectionPlanDialog() {
      this.inspectionPlanFormVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:21:45
     * @Description 选择线路后获取塔杆
     */
    getTowerByLineId(isClear, isPlan, lineId) {
      let params = {
        lineId: lineId
      };
      getTowerNameList(params).then(res => {
        this.currTowerList = res.data;
      });

      // 巡检任务表单需要多查询机器人和巡检模板
      if (isPlan) {
        /*
         * @Date 2022/02/14 13:31:47
         * 查询线路关联的机器人
         */
        this.inspectionPlanForm.robotId = "";
        getLineRobots({ lineId }).then(res => {
          this.robotList = res.data;
        });

        /*
         * @Date 2022/02/15 11:49:40
         * 根据线路查询巡检模板
         */
        getPlanTemplate({ lineId: lineId }).then(res => {
          this.taskTemplateList = res.rows;
        });
      }

      if (isClear) {
        this.inspectionPlanForm.beginTowerIndex = "";
        this.inspectionPlanForm.endTowerIndex = "";
        this.inspectionPlanForm.returnTowerIndex = "";

        this.inspectionTemplateForm.beginTowerIndex = "";
        this.inspectionTemplateForm.endTowerIndex = "";
        this.inspectionTemplateForm.returnTowerIndex = "";
      }

    
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:31:05
     * @Description 选择模板后回调
     */
    selectTemplates(id) {
      this.taskTemplateList.forEach(item => {
        if (item.id === id) {
          this.inspectionPlanForm.beginTowerIndex = item?.beginTower?.towerIndex;
          this.inspectionPlanForm.endTowerIndex = item?.endTower?.towerIndex;
          this.inspectionPlanForm.returnTowerIndex = item?.returnTower?.towerIndex;
          this.inspectionPlanForm.throughBeginTower = item?.beginTower?.isThroughout;
          this.inspectionPlanForm.throughEndTower = item?.beginTower?.isThroughout;
          this.inspectionPlanForm.throughReturnTower = item?.beginTower?.isThroughout;
          this.inspectionPlanForm.inspectMode = item?.inspectMode;
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:23:40
     * @Description 保存新增 / 修改的巡检任务
     */
    save(formName) {
      if (moment().valueOf() + 5 * 60 * 1000 >= moment(this.inspectionPlanForm.executeTime).valueOf()) {
        this.$message.error("执行时间应比当前时间晚五分钟！");
        return;
      }
      if (this.isAdd) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            postAddPlan(this.inspectionPlanForm).then(res => {
              if (res.code == "000000") {
                this.$message.success(res.mesg);
                this.inspectionPlanFormVisible = false;
                this.keepLastGetPlanList();
              } else {
                this.$message.error(res.mesg);
              }
            });
          } else {
            this.$message.error("请先填写符合规则的表单信息")
          }
        });
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            postUpdatePlan(this.inspectionPlanForm).then(res => {
              if (res.code == "000000") {
                this.$message.success(res.mesg);
                this.inspectionPlanFormVisible = false;
                this.keepLastGetPlanList();
              } else {
                this.$message.error(res.mesg);
              }
            });
          } else {
            this.$message.error("请先填写符合规则的表单信息")
          }
        });
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:26:43
     * @Description 显示任务模板表格
     */
    showTemplateTable(initFlag) {
      this.taskTemplateTableVisible = true;
      if(initFlag == "init") {
        this.pageNumber = 1
      }
      let params = {
        pageNumber: this.pageNumber,
        pageSize:this.pageSize
      }
      getPlanTemplate(params).then(res => {
        this.templateTableData = res.rows;
        console.log(this.templateTableData);
        this.tempTotal =res.total;
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:43:13
     * @Description 任务模板分页的时候序号计算
     */
    templateTableIndex(index) {
     return index+(this.pageNumber-1)*this.pageSize+1;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:28:44
     * @Description 显示添加模板对话框
     */
    addTemplateBtn() {
      this.inspectionTemplateFormVisible = true;
      // this.taskTemplateTableVisible = false;
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/06 10:29:22
     * @Description 取消添加模板
     */
    cancelAddTemplate() {
      this.inspectionTemplateFormVisible = false;
      // this.taskTemplateTableVisible = true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:29:29
     * @Description 保存新增或修改的模板
     */
    saveTemplate(formName) {
      if (this.isAddTemplate) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            postAddPlanTemplate(this.inspectionTemplateForm).then(res => {
              if (res.code === "000000") {
                this.inspectionTemplateFormVisible = false;
                this.$message.success(res.data);
                this.pageNumber = 1;
                this.showTemplateTable();
              } else {
                this.$message.error(res.data);
              }
            });
          }
        });
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            postUpdatePlanTemplate(this.inspectionTemplateForm).then(res => {
              if (res.code === "000000") {
                this.inspectionTemplateFormVisible = false;
                this.$message.success(res.data);
                this.showTemplateTable();
              } else {
                this.$message.error(res.meta.msg);
              }
            });
          }
        });
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:32:57
     * @Description 关闭新增任务或新增模板对话框时的回调
     */
    closedDialog(formName) {
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();

      this.planTemplate = "";
      this.isAdd = true;
      this.isAddTemplate = true;
      // this.pageNumber = 1;

      this.inspectionPlanForm.throughBeginTower = false;
      this.inspectionPlanForm.throughEndTower = false;
      this.inspectionPlanForm.throughReturnTower = false;

      this.inspectionTemplateForm.throughBeginTower = false;
      this.inspectionTemplateForm.throughEndTower = false;
      this.inspectionTemplateForm.throughReturnTower = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:30:09
     * @Description 删除模板
     */
    handleDeleteTemplate(row) {
      this.$confirm("此操作将删除该任务模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          getDeletePlanTemplate({ planId: row.id }).then(res => {
            if (res.code === "000000") {
              this.$message({
                type: "success",
                message: res.data
              });
              this.pageNumber = 1;
              this.showTemplateTable();
            } else {
              this.$message({
                type: "error",
                message: res.data
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除"
          });
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:30:18
     * @Description 修改模板
     */
    handleEditTemplate(row) {
      this.isAddTemplate = false;
      this.isAdd = false;
      this.getTowerByLineId(false, false, row.lineId);
      this.addTemplateBtn();

      this.$nextTick(() => {
        this.inspectionTemplateForm = {
          id: row.id,
          lineId: row.lineId,
          planName: row.templateName,
          inspectMode: row.inspectMode,
          beginTowerIndex: row.beginTower?.towerIndex,
          throughBeginTower: row.beginTower?.isThroughout,
          endTowerIndex: row.endTower?.towerIndex,
          throughEndTower: row.endTower?.isThroughout,
          returnTowerIndex: row.returnTower?.towerIndex,
          throughReturnTower: row.returnTower?.isThroughout
        };
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:41:36
     * @Description 任务模板分页改变
     */
    templatePageChange(pageNumber){
     this.pageNumber=pageNumber;
     this.showTemplateTable();
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:33:16
     * @Description 切换任务信息
     */
    showTask() {
      this.type = "plan";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:33:16
     * @Description 切换任务状态
     */
    showState() {
      this.type = "state";
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:36:46
     * @Description 编辑巡检任务
     */
    handleEdit(row) {
      console.log(row);
      this.inspectionPlanFormVisible = true;
      this.isAdd = false;

      this.$nextTick(() => {
        this.getTowerByLineId(false, true, row.lineId);
        this.inspectionPlanForm = {
          id: row.id,
          lineId: row.lineId,
          robotId: row.robotId,
          planName: row.planName,
          inspectMode: row.inspectMode,
          executeTime: row.executeTime,
          beginTowerIndex: row.beginTower.towerIndex,
          throughBeginTower: row.beginTower.isThroughout,
          endTowerIndex: row.endTower.towerIndex,
          throughEndTower: row.endTower.isThroughout,
          returnTowerIndex: row.returnTower?row.returnTower.towerIndex:"",
          throughReturnTower: row.returnTower?row.returnTower.isThroughout:false,
        };
      });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/06 10:37:05
     * @Description 删除巡检任务
     */
    handleDelete(row) {
      let params = {
        planId: row.id
      };
      //删除提示窗
      this.$confirm("此操作将永久删除该巡检任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          getDeletePlan(params).then(res => {
            if (res.code === "000000") {
              this.$message.success(res.mesg);
              this.keepLastGetPlanList();
            } else {
              this.$message.error(res.mesg);
            }
          });
        })
        .catch(() => {
          this.$message("已取消删除");
        });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/15 11:33:48
     * @Description 在增删改后，保持上一次的查询
     */
    keepLastGetPlanList() {
      if (this.isClickCalendar) {
        this.getInspectionPlanList(this.calendarDateString);
      } else {
        this.handleFilterPlan(this.isFilter);
      }
      this.getContainPlanDates(this.calendarDateString);
    },
    
    /**
     * @Author KR0132
     * @Date 2022/02/14 15:12:45
     * @Description 查询包含巡检任务的日期，并获取好对应的任务状态颜色列表
     */
    getContainPlanDates(date) {
      let dateStr = date || moment().format("yyyy-MM-DD");

      getContainPlanDateInMonth({ date: dateStr }).then(res => {
        if (res.code === "000000") {
          console.log(res.data);
          this.hasPlan = res.data.map(d => {
            let day = moment(d.date, "YYYY-MM-DD").date();
            if (d.planStatusList.length == 0) {
              return { date: day, colors: [] };
            } else {
              let colors = d.planStatusList.map(p => {
                let ps = this.planStatusColors.find(ps => {
                  if (ps?.status?.indexOf(p) > -1) {
                    return ps;
                  }
                });
                return ps ? ps.color : "";
              });

              return { date: day, colors: colors };
            }
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 10:03:12
     * @Description 处理分页更换
     */
    handleChangePage(currentPage) {
      this.pageNumber = currentPage;
      console.log(this.isFilter);
      if(this.isFilter) {
        this.handleFilterPlan(true);
      } else {
        let params = { 
          orderDate: this.calendarDateString, 
          pageNumber: this.pageNumber, 
          pageSize: this.pageSize 
        };
        getCheckPlans(params).then(res => {
          this.total = res.total;
          this.planTableData = res.rows;
        });        
      }
    },
  },

  watch: {
    month: {
      handler(newValue) {
        let date = moment(newValue).format("yyyy-MM");
        // let day = this.$refs.calendar.choosedDay || moment(newValue).get("date");
        let day = moment(newValue).get("date");
        if (day < 10) {
          day = "0" + day;
        }
        var dateStr = date + "-" + day;
        this.nowTime = Number(moment(dateStr).format("x"));     // 毫秒数
        this.getInspectionPlanList(dateStr);
        this.getContainPlanDates(dateStr);
      }
    },

    //监听机器的心跳变化和传感器的电量
    "$store.state.robotStatus": {
      handler(val) {
        var Index = "";
        var flag = this.robotStateTableData.some((item, index) => {
          if (item.id == val.id) {
            Index = index;
            return true;
          }
        });
        //如果集合中存在更新，否则添加
        if (flag) {
          this.robotStateTableData.splice(Index, 1, val); //替换
        } else {
          this.robotStateTableData.push(val);
        }
      },
    },

    inspectionPlanForm: {
      handler(newVal, oldVal) {
        if (newVal) {
          // 1、根据表单开始塔杆和结束塔杆号判断方向
          if (newVal.beginTowerIndex !== oldVal.beginTowerIndex || newVal.endTowerIndex !== oldVal.endTowerIndex) {
            if (newVal.beginTowerIndex !== "" && newVal.endTowerIndex !== "") {
              this.isDisabledDirection = true;
              this.inspectionPlanForm.dir = newVal.beginTowerIndex > newVal.endTowerIndex ? 2 : 1
            } else {
              this.isDisabledDirection = false;
            }
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.getPlanDictList();
    this.month = new Date().getTime();
    // this.getContainPlanDates(moment().format("yyyy-MM-DD"));
    this.getLineList();
  },

  activated() {
    let nowTime = moment().format('yyyy-MM-DD');
    this.nowTime = Number(moment(nowTime).format('x'));
    this.month = new Date().getTime();
    this.calendarDateString = nowTime
    let params = { 
      orderDate: nowTime, 
      pageNumber: 1, 
      pageSize: this.pageSize 
    };
    getCheckPlans(params).then(res => {
      this.total = res.total;
      this.planTableData = res.rows;
    });
    this.getContainPlanDates(nowTime);
  }
};
</script>
<style lang="scss" scoped>
.inspection-page {
  height: calc(100% - 8vh);
  padding: 0 2vw;
  color: #fff;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 8vh);
    margin-top: 2vh;
    .top-calendar {
    //  margin-top: 2vh;
      width: 100%;
      height: 36vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .month {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 1vh;
        position: relative;
        .add-button {
          position: absolute;
          right: 0;
          top: -1vh;
        }
        .el-button {
          padding: 1vh 1.5vh;
        }
      }
      .calendar {
        height: 27vh;
        padding-top: 1vh;
        position: relative;
        .up-line {
          position: absolute;
          top: 0;
        }
        .down-line {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  .pager-class {
    display: flex;
    justify-content: center;
  }
}
</style>
